<template>
  <div>
    <v-navigation-drawer
      app
      clipped
      right
      class="d-print-none"
      :style="{ zIndex: $zIndexConfig.sidebar }"
      v-model="showSidebar"
    >
      <sidebar v-if="showSidebar"></sidebar>
    </v-navigation-drawer>

    <v-app-bar
      eagle-header
      app
      fixed
      clipped-left
      elevation="0"
      class="d-print-none mainNav"
    >
      <!-- logo -->

      <div
        @click="goHref"
        class="d-flex justify-center align-center logoBox"
        :class="logoBoxClass"
      >
        <v-img contain width="25" height="25" :src="siteLogoUrl"></v-img>
      </div>

      <v-toolbar-title class="d-flex pa-2 align-center">
        <span class="pa-2">{{ siteName }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <div class="d-flex align-center" @click.stop="toggleSidebar">
        Menu&nbsp;<i class="ri-menu-line"></i>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { version } from "@/../package.json";

export default {
  props: {
    value: Boolean,
  },
  components: {
    sidebar: () => import("@/components/sidebar/sidebar.vue"),
  },
  data: () => ({
    showSidebar: false,
  }),
  computed: {
    ...mapGetters({
      siteLogoUrl: "base/siteLogoUrl",
    }),
    tokenRole() {
      return this.$store.getters["token/tokenRole"];
    },
    layoutProviderName() {
      return this.$store.getters["base/layoutProviderName"];
    },
    storeName() {
      return this.$store.getters["member/storeName"];
    },
    layout() {
      return this.$helper.whereLayout();
    },
    siteName() {
      // if (this.layout == "provider-store") return this.layoutProviderName + (this.storeName && `/${this.storeName}`)
      return this.layoutProviderName;
    },
    providerId() {
      if (this.tokenRole == "provider")
        return this.$store.getters["member/providerId"];
      return this.$route.params.providerId;
    },
    logoBoxClass() {
      if (this.layout == "provider-store") return "linerp-blue";
      return "linerp-orange";
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.showSidebar = this.value;
      },
    },
    showSidebar() {
      this.$emit("input", this.showSidebar);
    },
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    goHref() {
      if (this.tokenRole == "provider") {
        this.$router.push({
          name: "provider-orders",
        });
        return;
      }

      this.$router.push({
        name: "store-product-list",
        params: {
          providerId: this.providerId,
          auth: "auth",
        },
      });
    },
  },
};
</script>

<style lang="sass">
.mainNav
  .v-toolbar__content
    padding: 0 16px 0 0
    .logoBox
      width: 64px
      height: 64px
      flex: none
      &.linerp-blue
        background: #006DE0
      &.linerp-orange
        background: #FE5000

      @media screen and (max-width:960px)
        width: 56px
        height: 56px

.v-btn--is-elevated
  box-shadow: none !important
  .theme--light.v-btn:hover::before
    opacity: 0 !important
.v-list-item.self-v-list-item
  border-bottom: 2px solid #000
  min-height: 40px
  &:last-child
    border-bottom: none
  .v-list-item__title
    text-align: center !important
    .ri-arrow-drop-down-fill
      font-size: 2.5rem
  .menu-btn
    cursor: pointer
</style>
